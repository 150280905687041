import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API, ONDC_HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });
export const ondcAxiosInstance = axios.create({ baseURL: ONDC_HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

ondcAxiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const ondcFetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await ondcAxiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: (role: 'distributor' | 'aggregator') => `/${role}/info`,
    update: (role: 'distributor' | 'aggregator') => `${role}`,
    distributor: '/distributor/info',
    aggregator: '/aggregator/info',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
  retailers: {
    list: '/v2/distributor/retailers',
    add: 'v2/distributor/retailers',
    update: 'v2/distributor/retailers',
    updateLeads: 'distributor/retailers/new-leads',
    getOnboardingHistory: '/distributor/retailers/history',
  },
  delivery_person: {
    list: '/distributor/deliveryperson',
    add: 'distributor/deliveryperson',
    update: 'distributor/deliveryperson',
  },
  cpv: {
    list: '/distributor/cpv',
    execute: 'distributor/cpv',
    update: 'distributor/cpv',
    check: '/distributor/cpv/individual',
    checkPrice: '/distributor/cpv/balance_check',
    useExisting: '/distributor/cpv/individual',
  },
  enquiry: {
    list: '/distributor/enquiry',
    update: '/distributor/enquiry',
  },
  distributors: {
    list: '/aggregator/distributor',
    add: 'aggregator/distributor',
    delete: 'aggregator/distributor',
    update: 'aggregator/distributor',
    updateLeads: 'aggregator/distributor/new-leads',
    getOnboardingHistory: '/aggregator/distributor/history',
  },
  catalogue: {
    details: '/catalogue',
    list: '/distributor/catalogue',
    add: 'distributor/catalogue',
    fileUploadUrl: 'distributor/catalogue/file-upload',
    update: 'distributor/catalogue',
    history: '/distributor/catalogue/history',
    send: '/distributor/catalogue/message',
    deleteItem: 'distributor/catalogue',
  },
  upload: {
    fetchUrl: '/distributor/upload',
    processUpload: 'distributor/upload',
    downloadTemplate: '/distributor/templates',
  },
  prepaid: {
    statement: '/distributor/prepaid/statement',
    failedPending: 'distributor/prepaid/recharge',
    newRequest: 'distributor/prepaid/recharge',
    updateRequest: 'distributor/prepaid/recharge',
  },
  orders: {
    list: '/v2/distributor/order',
    add: 'distributor/order',
    send: 'distributor/order/info',
    update: 'distributor/order',
    ondc: {
      cancelReasons: 'cancel-reasons',
      transitionRules: 'transition-rules',
      updateStatus: 'update-order-status',
    },
  },
  invoice: {
    list: '/distributor/invoice',
    processUpload: 'distributor/invoice',
    send: 'distributor/invoice/link',
  },
  dashboard: {
    aggregator: '/aggregator/dashboard',
  },
};
